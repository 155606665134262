import 'intersection-observer';

function ViewportObserver(options) {

    this.observedElements = [];

    this.observer = new IntersectionObserver((entries, observer) => {

        entries.forEach((entry) => {

            this.observedElements.map((observedElement) => {

                const { element, callbacks } = observedElement;

                if ( element === entry.target && callbacks ) {

                    if ( typeof callbacks === 'function' ) {
                        callbacks(entry);
                    } else if ( callbacks.enter && callbacks.exit ) {
                        entry.intersectionRatio > 0 ? callbacks.enter(entry) : callbacks.exit(entry);
                    }

                }

            });

        });

    }, options);

}

ViewportObserver.prototype.observe = function( element, callbacks ) {

    this.observer.observe(element);

    this.observedElements.push({
        element: element,
        callbacks: callbacks
    });

}

export default ViewportObserver;