import anime from 'animejs';
import { Elastic } from 'easing-functions';

anime.easings['elasticOut'] = Elastic.Out;

function enter({ target: element }) {

    anime({
        easing: 'elasticOut',
        targets: element,
        delay: 250,
        duration: 750,
        opacity: [0, 1],
        translateY: [100, -0]
    });

}

function exit({ target: element }) {
    element.querySelector('strong').style.opacity = 0;
}

export default { enter, exit };