import menu from './components/menu';
import ViewportObserver from './components/viewport-observer';
import { throttle } from './lib';
import * as animations from './animations';
import Rellax from 'rellax';
import anime from 'animejs';
import contactForm from './components/form';

const header = document.getElementById('header');

// Dirty fix for forEach method on nodeLists
(function () {
    if ( typeof NodeList.prototype.forEach === "function" ) return false;
    NodeList.prototype.forEach = Array.prototype.forEach;
})();

// Setup the menu
menu({
    menuItems: document.querySelectorAll('.header__nav li, #navigation-overlay li'),
    sections: document.querySelectorAll('.section')
});

// Setup contactForm
contactForm();

// Setup fixed header
const watchFixedHeader = (e) => {
    if ( window.scrollY > header.scrollHeight && ! header.classList.contains( 'header--fixed' ) ) {
        header.classList.add('header--fixed');
        anime({
            targets: header,
            easing: 'easeOutQuad',
            duration: 250,
            translateY: ['-100%', 0]
        });
    } else if( window.scrollY <= header.scrollHeight && header.classList.contains( 'header--fixed' ) ) {
        header.classList.remove('header--fixed');
    }
}
window.addEventListener('scroll', throttle(watchFixedHeader, 150));
watchFixedHeader();

document.querySelector('.header__nav-toggle').addEventListener('click', (e) => {

    const activeClass = 'navigation-overlay--active';
    const overlay = document.getElementById('navigation-overlay');
    const isOpen = overlay.classList.contains(activeClass);

    const animation = anime({
        autoplay: false,
        delay: (el, i) => 150 + (i * 50),
        duration: 500,
        targets: overlay.querySelectorAll('li'),
        opacity: [0, 1],
        translateY: [-25, 0]
    });

    if ( isOpen ) {
        overlay.classList.remove(activeClass);
        e.target.classList.remove('header__nav-toggle--active');
        animation.reset();
    } else {
        overlay.classList.add(activeClass);
        e.target.classList.add('header__nav-toggle--active');
        animation.play();
    }

});

document.querySelectorAll('#navigation-overlay li').forEach((overlayLink) => {
    overlayLink.addEventListener('click', (e) => {
        console.log('click overlay li');
        document.querySelector('.header__nav-toggle').classList.remove('header__nav-toggle--active');
        document.getElementById('navigation-overlay').classList.remove('navigation-overlay--active');
    });
});

// Setup intro animation on document load
document.addEventListener('DOMContentLoaded', animations.intro.enter);

// Setup animations (dependant on viewport observing)
const viewportObserver = new ViewportObserver({
    rootMargin: `${header.scrollHeight}px 0px 0px 0px`
});

viewportObserver.observe( document.querySelector('.usps'), {
    enter: animations.usps.enter,
    exit: animations.usps.exit
} );

document.querySelectorAll('.service').forEach((service) => {
    viewportObserver.observe( service, {
        enter: animations.services.enter,
        exit: animations.services.exit
    } );
});

document.querySelectorAll('.team .member').forEach((teamMember) => {
    viewportObserver.observe( teamMember, {
        enter: animations.team.enter,
        exit: animations.team.exit
    } );
});

document.querySelectorAll('.testimonials .review').forEach((review) => {
    viewportObserver.observe( review, {
        enter: animations.review.enter,
        exit: animations.review.exit
    } );
});

viewportObserver.observe( document.querySelector('.portfolio'), {
    enter: (entry) => {
        anime({
            easing: 'easeOutSine',
            targets: entry.target,
            delay: 500,
            duration: 500,
            opacity: [0, 1],
            translateY: [10, 0]
        });
    },
    exit: (entry) => entry.target.style.opacity = 0
} );


viewportObserver.observe( document.getElementById('contact'), {
    enter: animations.contact.enter,
    exit: animations.contact.exit
} );

// Parallax effectss
new Rellax('.js-parallax');
