import anime from 'animejs';

function enter({ target: element }) {
    element.style.opacity = 1;
    anime({
        easing: 'easeOutSine',
        targets: element,
        delay: 500,
        duration: 500,
        opacity: [0, 1],
        translateX: element.classList.contains('review--alt') ? [200, 0] : [-200, 0]
    });
}

function exit({ target: element }) {
    element.style.opacity = 0;
}

export default { enter, exit };