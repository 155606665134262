import anime from 'animejs';
import { Elastic } from 'easing-functions';
import { encode, serializeArray } from '../lib';
import formValidator from './form-validator';




function contactForm() {

    let form;

    let loadingClass;

    let validator;

    const _clearErrors = () => {
        form.querySelectorAll('.form__field-error').forEach((element) => element.remove() );
    }

    const _submissionError = (error) => {

        const errorElement = document.createElement('div');

        errorElement.classList.add('form__field-error');
        errorElement.innerHTML = `<p>${error}</p>`;

        form.insertBefore(errorElement, form.childNodes[0]);

    }

    const _submissionComplete = () => {

        // Create an animation timeline with options for all sequences
        const timeline = anime.timeline({
            duration: 500
        });

        const formSection = document.querySelector('.section__form');
        const messageSection = document.querySelector('.section__message');

        // Exit the form section, remove element when animation is complete
        timeline.add({
            easing: 'easeOutQuart',
            targets: formSection,
            complete: () => formSection.remove(),
            opacity: 0,
            translateY: [0, 100]
        });

        // Enter the succes message
        messageSection.style.display = 'block';
        timeline.add({
            easing: 'easeInOutQuart',
            targets: messageSection,
            opacity: [0, 1],
            translateY: [100, 0]
        });

        // Remove loading state
        form.classList.remove(loadingClass);

        // Construct an animation timeline and play it


        console.log( 'Form succesfully sent' );

    }

    function _getRequestData() {

        let formData = {};

        // Fill the formData object with all form fields
        form.querySelectorAll('[name]').forEach((element) => {
            formData[element.getAttribute('name')] = element.value;
        });

        // Construct request data
        const requestData = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: encode(formData)
        }

        console.log( 'formData', formData );

        return requestData;

    }

    function _onSubmit(e) {

        const isValid = validator.validate(e);

        e.preventDefault();

        if (!isValid) {
            return _submissionError('Oeps, er ontbreken nog wat velden.');
        }

        // Add loading class to form
        form.classList.add(loadingClass);

        fetch('/', _getRequestData()).then((response) => {

            // Check if the request succeeded
            if ( ! response.ok ) {
                _submissionError('Er ging iets mis bij het verzenden van het formulier. Probeer het later nog eens.');
            } else {
                _submissionComplete();
            }

            // Remove loading class from form
            form.classList.remove(loadingClass);

        });



    }

    const _init = () => {

    }

    const control = (config) => {

        config = config || {};

        form = config.form || document.querySelector('form[name="contact"]');

        loadingClass = config.loadingClass || 'form--loading';

        validator = formValidator(form);

        form.addEventListener('submit', _clearErrors);
        form.addEventListener('blur', _clearErrors, true);
        form.addEventListener('submit', _onSubmit);

        form.setAttribute('novalidate', 'true');

        _init();

    }

    return control;
}

export default contactForm();
