import anime from 'animejs';

const usps = document.querySelectorAll('.usps .usp');

const animation = anime({
    autoplay: false,
    easing: 'easeOutSine',
    delay: (el, i) => 150 + (i * 100),
    duration: 500,
    targets: usps,
    opacity: [0, 1],
    translateY: [100, 0]
});

function enter({ target: element }) {
    animation.play();
}

function exit({ target: element }) {
    animation.reset();
}

export default { enter, exit };