import anime from 'animejs';

function enter({ target: element }) {
    element.style.opacity = 1;
    anime({
        easing: 'easeOutSine',
        targets: element.querySelector('.service__wrap').children,
        delay: (el, i) => 250 + (i * 150),
        duration: 500,
        opacity: [0, 1],
        translateX: element.classList.contains('service--align-right') ? [100, 0] : [-100, 0]
    });
}

function exit({ target: element }) {
    element.style.opacity = 0;
}

export default { enter, exit };