import anime from 'animejs';

function enter({ target: element }) {

    const yDistance = 50;

    element.style.opacity = 1;

    anime({
        easing: 'easeOutSine',
        targets: element.children,
        delay: (el, i) => 250 + (i * 150),
        duration: 500,
        opacity: [0, 1],
        // translateY: [yDistance, 0],
        translateX: (el, i) => {
            if ( element.classList.contains('member--align-right') ) {
                return el.classList.contains('member__image') ? [-yDistance, 0] : [yDistance, 0];
            } else {
                return el.classList.contains('member__image') ? [yDistance, 0] : [-yDistance, 0];
            }
        },
        // translateY: (el, i) => {
        //     if ( element.classList.contains('member--align-right') ) {
        //         return el.classList.contains('member__image') ? [-yDistance, 0] : [yDistance, 0];
        //     } else {
        //         return el.classList.contains('member__image') ? [yDistance, 0] : [-yDistance, 0];
        //     }
        // }
    });

}

function exit({ target: element }) {
    element.style.opacity = 0;
}

export default { enter, exit };