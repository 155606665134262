import 'intersection-observer';
import { range, invert } from '../lib';
import anime from 'animejs';
import { chapter as chapterAnimation } from '../animations';

const menu = () => {

    let sectionsVisibility = [];

    let activeSection = false;

    let activeClass;

    let menuItems;

    let sections;

    let nav;

    const _intersect = ( entries ) => {

        entries.forEach((entry) => {
            sectionsVisibility[ entry.target.getAttribute('id') ] = entry.intersectionRatio;
        });

        let sectionsByIntersectionValue = invert(sectionsVisibility);
        let largestIntersectionRatio = Math.max(...Object.values(sectionsVisibility));
        let dominantSection = sectionsByIntersectionValue[ largestIntersectionRatio ];

        if ( dominantSection !== activeSection ) {
            _setActiveSection(dominantSection);
        }

    }

    const _setActiveSection = ( section ) => {

        menuItems.forEach((menuItem) => {
            if (menuItem.querySelector('a').getAttribute('href').substr(1) === section) {
                return menuItem.classList.add(activeClass);
            }
            return menuItem.classList.remove(activeClass);
        });

    }

    const _smoothScroll = ( e ) => {
        const targetSection = document.getElementById(e.currentTarget.getAttribute('href').substr(1));
        const chapterNumberElement = targetSection.querySelector('.section__chapter strong');
        const { top } = targetSection.getBoundingClientRect()
        const navHeight = 50; //nav.scrollHeight
        const scroll = {
            y: window.pageYOffset
        }

        // Hide the chapter number before we scroll there.
        if ( chapterNumberElement ) {
            chapterNumberElement.style.opacity = 0;
        }

        // Animated scroll to the section
        anime({
            targets: scroll,
            y: ((top + window.pageYOffset) - navHeight),
            duration: 750,
            easing: 'easeInOutCubic',
            update: () => window.scroll(0, scroll.y),
            // On complete, enter the chapter number
            complete: () => {
                if ( chapterNumberElement ) {
                    chapterAnimation.enter({ target: chapterNumberElement })
                }
            }
        })

        e.preventDefault();
    }

    const _init = () => {

        const sectionObserver = new IntersectionObserver(_intersect, {
            threshold: range(0, 1.0, 0.1)
        });

        sections.forEach((section) => {
            sectionObserver.observe(section);
        });

        menuItems.forEach((menuItem) => {
            menuItem.querySelector('a').addEventListener('click', _smoothScroll);
        });

        document.querySelectorAll('.js-smooth-scroll[href^="#"]').forEach(link => link.addEventListener('click', _smoothScroll));

    }

    let control = (config) => {

        if ( typeof config.menuItems === 'string' ) {
            menuItems = document.querySelectorAll(config.menuItems);
        } else {
            menuItems = config.menuItems;
        }

        if ( typeof config.sections === 'string' ) {
            sections = document.querySelectorAll(config.sections);
        } else {
            sections = config.sections;
        }

        activeClass = config.activeClass || 'active';

        nav = menuItems[0].parentElement.parentElement;

        _init();

    }

    return control;

}

export default menu();
