import anime from 'animejs';

function enter({ target: element }) {

    element.style.opacity = 1;

    anime({
        easing: 'easeOutSine',
        delay: 500,
        duration: 500,
        targets: element.querySelector('.footer__form'),
        translateX: [-25, 0],
        // translateY: [25, 0],
        opacity: [0, 1],
    });
}

function exit({ target: element }) {
    element.style.opacity = 0;
}

export default { enter, exit };
