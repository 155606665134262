import anime from 'animejs';

const introSection = document.getElementById('intro');
const header = introSection.querySelector('.intro__header');
const subtitle = introSection.querySelector('.intro__subtitle');
const intro = introSection.querySelector('.intro__text');
const cta = introSection.querySelector('.intro__cta');

const timeline = anime.timeline();

timeline.add({
    easing: 'easeInOutSine',
    duration: 750,
    delay: (el, i) => 250 + (i * 100),
    targets: [
        header,
        subtitle
    ],
    opacity: [0, 1],
    translateY: [100, 100]
});

timeline.add({
    easing: 'easeInOutSine',
    duration: 500,
    delay: (el, i) => 250 + (i * 100),
    targets: [header, subtitle],
    translateY: 0
});

timeline.add({
    easing: 'easeInCubic',
    duration: 500,
    delay: (el, i) => i * 100 ,
    targets: [intro, cta],
    opacity: [0, 1],
    // translateY: [20, 0],
    // scale: [0.95, 1],
    offset: '-=250'
});


function enter() {
    timeline.play();
}

export default { enter };