
function formValidator() {

    let form;

    const _removeFieldError = (field) => {
        field.parentNode.classList.remove(field.parentNode.classList[0]+'--error');
    }

    const _showFieldError = (field, error) => {
        field.parentNode.classList.add(field.parentNode.classList[0]+'--error');
    }

    const _fieldHasError = (field) => {

        // Don't validate submits, buttons, file and reset inputs, and disabled fields
        if (
            field.disabled
            || field.type === 'file'
            || field.type === 'reset'
            || field.type === 'submit'
            || field.type === 'button'
            || field.validity.valid
        ) {
            return;
        };

        let validity = field.validity;

        if (validity.valueMissing) {
            return 'Dit veld is verplicht.'
        };

        if (validity.typeMismatch && field.type === 'email') {
            return 'Voer een geldig e-mail adres in.';
        }

        return 'Voer een geldige waarde in.';

    }

    const _validateField = (object) => {

        let field = object.target || object;

        let fieldError = _fieldHasError(field);

        if ( fieldError ) {
            return _showFieldError(field, fieldError);
        }

        _removeFieldError(field);

    }

    const validate = (e) => {

        e.preventDefault();
        let errors = [];

        let fields = e.targets || form.querySelectorAll('[name]');

        fields.forEach((field) => {

            let fieldError = _fieldHasError(field);

            _validateField(field);

            if (fieldError) {
                errors.push(fieldError);
            }

        }, this);

        return errors.length === 0;

    }

    const control = (formElement) => {

        form = typeof formElement === 'string' ? document.querySelector(formElement) : formElement;

        form.addEventListener('submit', validate);
        form.addEventListener('blur', _validateField, true);

        return {
            validate: validate
        }

    }

    return control;

}

export default formValidator();